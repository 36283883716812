<template>
  <div class="campaignManage">
    <div style="margin-bottom: 20px; overflow: hidden">
      <div style="float: left">
        <el-button
          icon="el-icon-plus"
          size="mini"
          type="primary"
          @click="creatCampaign"
          >创建活动
        </el-button>
        <el-button
          size="mini"
          type="primary"
          @click="intoCampaignCompare"
          :disabled="
            campaignSelection.length < 2 || campaignSelection.length > 5
          "
        >
          <svg-icon class-name="duibi"></svg-icon>
          多活动对比
        </el-button>
      </div>

      <!-- 活动筛选 -->
      <div style="float: left">
        <span class="filterBtn" @click="tagshow = !tagshow">
          {{ tagshow ? "收起" : "筛选" }}&nbsp;&nbsp;
          <i v-show="tagshow" class="el-icon-arrow-up"></i>
          <i v-show="!tagshow" class="el-icon-arrow-down"></i>
        </span>
      </div>

      <!-- 活动搜索 -->
      <div style="float: right">
        <el-input
          v-model="searchWord"
          placeholder="请输入内容"
          size="small"
          clearable
          @clear="clearSearchWord"
          @keyup.enter="initCampaign"
        >
          <template #prepend>
            <el-select
              v-model="searchField"
              placeholder="请选择"
              style="width: 115px"
            >
              <el-option label="活动名称" value="campaignName"></el-option>
              <el-option label="KOL 名" value="kolName"></el-option>
            </el-select>
          </template>
          <template #append>
            <el-button @click="initCampaign" icon="el-icon-search"></el-button>
          </template>
        </el-input>
      </div>
    </div>

    <div class="filterDiv" v-show="tagshow">
      <el-form :model="filterForm" label-position="right" label-width="70px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="品牌">
              <el-radio-group size="mini" v-model="filterForm.filterBrand">
                <el-radio-button label="">全部</el-radio-button>
                <el-radio-button
                  :key="item.id"
                  v-for="item in comBrandList"
                  :label="item.label"
                ></el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :offset="2" :span="10">
            <el-form-item label="活动状态">
              <el-radio-group size="mini" v-model="filterForm.campaginStatus">
                <el-radio-button label="">全部</el-radio-button>
                <el-radio-button label="0">活动未开始</el-radio-button>
                <el-radio-button label="6">活动进行中</el-radio-button>
                <el-radio-button label="7">活动已完成</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="活动类型">
              <el-radio-group size="mini" v-model="filterForm.campaginType">
                <el-radio-button label="">全部</el-radio-button>
                <el-radio-button
                  :key="index"
                  v-for="(item, index) in campaignTypeList"
                  :label="item"
                ></el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :offset="2" :span="12">
            <el-form-item label="报告状态">
              <el-radio-group
                size="mini"
                v-model="filterForm.reportStatusFilter"
              >
                <el-radio-button label="">全部</el-radio-button>
                <el-radio-button label="1">未创建</el-radio-button>
                <el-radio-button label="2">进行中</el-radio-button>
                <el-radio-button label="3">成功</el-radio-button>
                <el-radio-button label="4">部分失败</el-radio-button>
                <el-radio-button label="5">全部失败</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <el-table
      ref="multipleTable"
      :header-cell-style="headerStyle"
      :cell-style="cellStyle"
      :data="campaignTableInfo"
      v-loading="campaignTableLoading"
      @selection-change="handleSelectionChange"
      size="mini"
    >
      <el-table-column type="selection" :selectable="disabledSelect" width="30">
      </el-table-column>
      <el-table-column width="50" align="center" prop="index" label="序号">
      </el-table-column>
      <el-table-column
        align="center"
        prop="campaignName"
        label="活动名称"
        width="100"
      >
        <template #default="{ row }">
          <span
            style="cursor: pointer; color: #51a6f0"
            @click="campaignInfo(row)"
          >
            {{ row.campaignName }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        width="80"
        align="center"
        prop="campaignType"
        label="活动类型"
      >
      </el-table-column>
      <el-table-column align="center" prop="brand" width="100" label="活动品牌">
      </el-table-column>
      <!-- brandLine -->
      <el-table-column align="center" prop="brandLine" width="80" label="品线">
      </el-table-column>
      <el-table-column
        align="center"
        prop="campaignDesc"
        label="活动说明"
        width="100"
      >
      </el-table-column>
      <el-table-column align="center" prop="duration" label="活动时间">
      </el-table-column>
      <el-table-column align="center" prop="creator" width="80" label="创建者">
      </el-table-column>
      <el-table-column
        align="center"
        sortable
        prop="createTimeStr"
        label="创建时间"
        width="200"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="campaignStatus"
        label="活动状态"
        width="120"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="reportStatus"
        label="报告状态"
        width="80"
      >
        <template #default="{ row }">
          <el-tooltip
            effect="dark"
            :content="setReportStatus(row)"
            placement="top"
          >
            <svg-icon
              style="font-size: 24px"
              :class-name="row.reportStatus"
              :class="row.reportStatus"
            />
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="action" label="操作" width="140">
        <template #default="{ row }">
          <el-tooltip effect="dark" content="详情" placement="top">
            <svg-icon
              class="svg_icon"
              class-name="iconlvzhou_gengduo_xiangqing"
              @click="campaignInfo(row)"
            />
          </el-tooltip>
          <el-tooltip
            effect="dark"
            :content="row.isDelDisable ? '活动不能删除' : '删除'"
            placement="top"
          >
            <svg-icon
              class-name="iconshanchu"
              @click="delCampaign(row)"
              :class="['svg_icon', row.isDelDisable ? 'disableDelClass' : '']"
            />
          </el-tooltip>
          <el-tooltip
            effect="dark"
            :content="
              row.isUpdateDisable ? '正在报价中无法更新' : '更新活动状态'
            "
            placement="top"
          >
            <svg-icon
              @click="updateCampaign(row)"
              class-name="update"
              :class="[
                'svg_icon',
                row.isUpdateDisable ? 'disableUpdateClass' : '',
              ]"
            />
          </el-tooltip>
          <el-tooltip effect="dark" content="活动报告" placement="top">
            <svg-icon
              class-name="report"
              class="svg_icon"
              style="margin-right: 0"
              @click="intoCampaignReport(row.id, row.campaignName, row)"
            />
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div class="campaignPaginationBox">
      <el-pagination
        background
        :current-page="currentPage"
        :page-size="pageSize"
        @current-change="handleCampaignCurrentChange"
        layout="total, prev, pager, next, jumper"
        :total="campaignTotal"
      >
      </el-pagination>
      <div class="amount" v-show="colTotal !== 'inf'">
        目前使用: {{ collected }} , 总额度: {{ colTotal }}
      </div>
    </div>
    <!-- 更新活动状态 -->
    <el-dialog title="更新活动状态" v-model="dialogUpdateCampaign" width="30%">
      <el-select v-model="campaignStatus" placeholder="请选择">
        <el-option
          v-for="item in campaignStatusList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <template #footer>
        <div>
          <el-button @click="dialogUpdateCampaign = false">取 消</el-button>
          <el-button type="primary" @click="updateCampaignStatus"
            >确 定
          </el-button>
        </div>
      </template>
    </el-dialog>
    <!-- 删除活动 -->
    <el-dialog title="警告" v-model="delDialog" width="30%">
      <p>确定要删除此活动吗？</p>
      <template #footer>
        <div>
          <el-button @click="delDialog = false">取 消</el-button>
          <el-button type="primary" @click="realDel">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCampaignList,
  updateCampaignSatus,
  delCampaign,
  getOption,
  getAgency,
  getCampaignBrand,
  getCampaignType,
  getAmount,
  getCampaignBrandLine,
  getCampaignProduct,
} from "@/api/campaign";

export default {
  data() {
    return {
      // 表格样式
      headerStyle: {
        backgroundColor: "#F9FCFE",
        borderTopWidth: "1px",
        borderTopColor: "#eee",
        borderTopStyle: "solid",
      },
      cellStyle: {
        fontSize: "12px",
      },
      campaignTableLoading: true,
      campaignTableInfo: [],
      // 初始化活动数据
      currentPage: 1,
      pageSize: 10,
      campaignTotal: 0,
      sortColumn: "create_time",
      sortDirection: "desc",
      // 更新活动状态数据
      dialogUpdateCampaign: false,
      campaignStatus: "",
      campaignStatusList: [
        {
          value: "0",
          label: "活动未开始",
        },
        {
          value: "6",
          label: "活动进行中",
        },
        {
          value: "7",
          label: "活动完成",
        },
      ],
      // update campaign id
      updateCampaignId: 0,
      // 匹配报价中正则
      priceRegEep: new RegExp("第[1-9][0-9]*轮报价中"),
      // 删除数据
      delDialog: false,
      delId: 0,
      // 审批数据
      campaignSelection: [],
      campaignSelectionTable: [],
      mapCampaignStatus: {
        0: "活动未开始",
        6: "活动进行中",
        7: "活动完成",
      },
      mapReportStatus: {
        1: "noStart",
        2: "loading",
        3: "done",
        4: "halfFail",
        5: "fail",
      },
      // 筛选触发 flag
      tagshow: false,
      // 搜索词
      searchWord: "",
      // 搜索字段
      searchField: "campaignName",
      // 筛选form
      filterForm: {
        filterBrand: "",
        campaginStatus: "",
        campaginType: "",
        reportStatusFilter: "",
      },
      comBrandList: [],
      campaignTypeList: [],
      brandListList: [],
      productList: [],
      colTotal: 0,
      collected: 0,
    };
  },
  mounted() {
    this.initCampaign();
    this.initOption();
    this.getAmount();
  },
  methods: {
    async getCampaignBrandLineFunc() {
      let { code, data } = await getCampaignBrandLine();
      if (code === 0) {
        data.forEach((v) => {
          this.brandListList.push({
            value: v,
            label: v,
          });
        });
      }
      this.$storage.setItem("brandLineList", this.brandListList);
    },
    async getCampaignProductFunc() {
      let { code, data } = await getCampaignProduct();
      if (code === 0) {
        data.forEach((v) => {
          this.productList.push({
            value: v,
            label: v,
          });
        });
      }
      this.$storage.setItem("productList", this.productList);
    },
    async getAmount() {
      let { code, data } = await getAmount();
      if (code === 0) {
        this.collected = data.used;
        this.colTotal = data.quota === null ? "inf" : data.quota;
      }
    },
    clearSearchWord() {
      this.searchWord = "";
      this.initCampaign();
    },
    setReportStatus(data) {
      switch (data.reportStatus) {
        case "loading":
          return "生成中";
        case "done":
          return "已完成";
        case "noStart":
          return "未创建";
        case "halfFail":
          return "部分失败";
        case "fail":
          return "失败";
      }
    },
    async initCampaign() {
      this.campaignTableLoading = true;
      let params = {
        page: this.searchWord.trim() === "" ? this.currentPage : 1,
        size: this.pageSize,
        sortColumn: this.sortColumn,
        sortDirection: this.sortDirection,
        brand: this.filterForm.filterBrand,
        campaignType: this.filterForm.campaginType,
        campaignStatus: this.filterForm.campaginStatus,
        reportStatus: this.filterForm.reportStatusFilter,
        searchField: this.searchField,
        searchWord: this.searchWord.trim(),
      };
      let res = await getCampaignList(params);
      if (res.code === 0) {
        res.data.campaignInfos.forEach((v, i) => {
          // 判断是否可以删除
          if (
            v.campaignStatus === "1" ||
            v.campaignStatus === "4" ||
            v.campaignStatus === "8"
          ) {
            v.isDelDisable = true;
          }
          if (v.reportStatus === 2) {
            v.isDelDisable = true;
          }
          // mapCampaignStatus
          if (v.campaignStatus.includes("_")) {
            v.campaignStatus = `报价中`;
            v.isUpdateDisable = true;
          } else {
            v.campaignStatus = this.mapCampaignStatus[v.campaignStatus];
          }

          // mapReportStatus
          v.reportStatus = this.mapReportStatus[v.reportStatus];

          // campaign index
          v.index = ++i + this.pageSize * (this.currentPage - 1);

          v.duration = v.duration.replace(",", " 至 ");

          this.campaignTotal = res.data.totalNum;
        });
        this.judgeHaveSelectData(res.data.campaignInfos);
        setTimeout(() => {
          this.campaignTableLoading = false;
        }, 20);
      }
    },
    async initOption() {
      let brandList = await getCampaignBrand();
      let compaginType = await getCampaignType();
      let res = await getOption();
      let agency = await getAgency();
      this.getCampaignBrandLineFunc();
      this.getCampaignProductFunc();

      //
      if (compaginType.code === 0) {
        this.campaignTypeList = compaginType.data;
        this.$storage.setItem("campaignTypeList", this.campaignTypeList);
      }
      // 本地存储品牌数据
      if (brandList.code === 0) {
        this.comBrandList = this.formatBrand(brandList.data);
        this.$storage.setItem("brandList", this.formatBrand(brandList.data));
      }
      // 本地存储option数据
      if (res.code === 0) {
        this.$storage.setItem(
          "coopList",
          this.formatList(res.data.coopList, "coopName")
        );
        this.$storage.setItem(
          "rightsList",
          this.formatList(res.data.rightsList, "rightsName")
        );
        this.$storage.setItem(
          "contentList",
          this.formatList(res.data.contentList, "rightsName")
        );
      }

      // 本地存储供应商
      if (agency.code === 0) {
        this.$storage.setItem("agencyList", this.formatAgency(agency.data));
      }
    },
    formatBrand(data) {
      let brandList = [];
      data.forEach((v) => {
        brandList.push({
          label: v.brandName,
          value: v.id,
          id: v.id,
        });
      });
      return brandList;
    },
    // 判断列表中有无选中的数据
    judgeHaveSelectData(data) {
      const res = this.campaignSelectionTable.filter((sel) =>
        data.find((i) => sel.id === i.id)
      );
      const selectIndexArr = res.map((r) =>
        this.campaignSelectionTable.findIndex((i) => i.id === r.id)
      );
      const dataIndexArr = res.map((r) => data.findIndex((i) => i.id === r.id));
      for (let i = 0; i < dataIndexArr.length; i++) {
        data.splice(
          dataIndexArr[i],
          1,
          this.campaignSelectionTable[selectIndexArr[i]]
        );
      }
      const tempArr = [].concat(this.campaignSelectionTable);
      selectIndexArr.sort((a, b) => b - a).forEach((i) => tempArr.splice(i, 1));
      this.campaignTableInfo = tempArr.concat(data);
    },

    formatAgency(data) {
      let tempList = [];
      data.forEach((v) => {
        tempList.push({
          label: v.agencyName,
          value: v.agencyName,
          id: v.id,
        });
      });
      return tempList;
    },
    formatList(data, name) {
      let list = {
        all: [],
        wechat: [],
        weibo: [],
        douyin: [],
        xiaohongshu: [],
        bilibili: [],
      };
      let res = new Map();
      data.forEach((v) => {
        list.all.push({
          label: v[name],
          id: v.id,
          value: v[name],
        });
        switch (v.platform) {
          case "wechat":
            list.wechat.push({
              label: v[name],
              id: v.id,
              value: v[name],
            });
            break;
          case "weibo":
            list.weibo.push({
              label: v[name],
              id: v.id,
              value: v[name],
            });
            break;
          case "douyin":
            list.douyin.push({
              label: v[name],
              id: v.id,
              value: v[name],
            });
            break;
          case "xiaohongshu":
            list.xiaohongshu.push({
              label: v[name],
              id: v.id,
              value: v[name],
            });
            break;
          case "bilibili":
            list.bilibili.push({
              label: v[name],
              id: v.id,
              value: v[name],
            });
            break;
        }
      });
      list.all = list.all.filter(
        (a) => !res.has(a.value) && res.set(a.value, 1)
      );
      return list;
    },
    // 路由跳转
    creatCampaign() {
      this.$router.push({ name: "CreateCampaign" });
    },
    intoCampaignCompare() {
      const query = {
        campaigns: this.btoa(this.campaignSelection.toString()),
      };
      this.$router.push({ name: "CampaignCompare", query });
    },
    campaignInfo(row) {
      if (
        row.campaignStatus !== "报价中" &&
        row.campaignStatus !== "等待报价"
      ) {
        this.$storage.setItem("quoteFinished", 1);
      } else {
        this.$storage.setItem("quoteFinished", 0);
      }
      this.$storage.setItem("activeName", row.campaignName);
      this.$router.push({
        name: "CampaignInfo",
        query: { id: this.btoa(row.id) },
      });
    },
    intoCampaignReport(id, name, row) {
      if (
        row.campaignStatus !== "报价中" &&
        row.campaignStatus !== "等待报价"
      ) {
        this.$storage.setItem("quoteFinished", 1);
      } else {
        this.$storage.setItem("quoteFinished", 0);
      }
      this.$storage.setItem("activeName", name);
      const query = { id: this.btoa(id) };
      this.$router.push({ name: "CampaignReport", query });
    },
    // 更新活动状态
    updateCampaign(row) {
      // 如果禁用按钮 则直接return
      if (row.isUpdateDisable) {
        return;
      }
      if (this.priceRegEep.test(row.campaignStatus)) {
        return;
      }
      this.updateCampaignId = row.id;
      this.campaignStatus = row.campaignStatus;
      this.dialogUpdateCampaign = true;
    },
    async updateCampaignStatus() {
      let params = {
        id: this.updateCampaignId,
        status: this.campaignStatus,
      };
      let { code } = await updateCampaignSatus(params);
      if (code === 0) {
        this.$message({
          message: "活动状态更新成功!",
          type: "success",
        });
        this.campaignStatus = "";
        this.dialogUpdateCampaign = false;
        await this.initCampaign();
      }
    },
    // 删除活动
    delCampaign(row) {
      // 如果禁用按钮 则直接return
      if (row.isDelDisable) {
        return;
      }
      this.delDialog = true;
      this.delId = row.id;
    },
    async realDel() {
      let { code } = await delCampaign({ id: this.delId });
      if (code === 0) {
        this.$message({
          message: "删除活动成功!",
          type: "info",
        });
        this.delDialog = false;
        await this.initCampaign();
      }
    },
    async handleCampaignCurrentChange(cur) {
      this.campaignTableLoading = true;
      this.currentPage = cur;
      await this.initCampaign();
      this.campaignSelectionTable.forEach((row) => {
        this.$refs.multipleTable.toggleRowSelection(row);
      });
    },
    handleSelectionChange(val) {
      this.campaignSelectionTable = val;
      this.campaignSelection = val.map((c) => c.id);
    },
    disabledSelect({ reportStatus, id }) {
      if (this.campaignSelection.includes(id)) {
        return true;
      } else if (this.campaignSelection.length > 4) {
        return false;
      } else return reportStatus === "done" || reportStatus === "halfFail";
    },
  },
  watch: {
    filterForm: {
      handler() {
        this.currentPage = 1;
        this.initCampaign();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.campaignManage {
  .el-form-item__label {
    color: #99a9bf;
  }
  .el-form-item .el-form-item__label {
    text-align: right;
  }
  // .el-form-item__content {
  //   margin-left: 70px !important;
  // }
  .el-radio-button__inner {
    border-radius: 5px !important;
    border: 1px solid #dcdfe6;
  }
  .el-radio-button.el-radio-button--mini {
    float: left;
    margin-right: 10px;
    margin-left: 5px;
  }
  .el-row .el-col .el-form-item {
    margin-bottom: 0;
  }
}
</style>

<style scoped lang="scss">
.campaignManage {
  background-color: #fff;
  padding: 10px 5px;
  .filterDiv {
    padding: 10px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
  }
  .filterBtn {
    line-height: 32px;
    margin-left: 40px;
    color: #888;
    cursor: pointer;
    font-weight: 700;
    &:hover {
      color: #51a6f0;
    }
  }
  .loading {
    fill: #e6a23c;
  }

  .done {
    fill: #04be02;
  }

  .halfFail {
    fill: rgb(255, 121, 70);
  }

  .fail {
    fill: red;
  }

  .noStart {
    fill: rgb(194, 194, 194);
    font-size: 23px;
    position: relative;
    right: 1px;
  }

  .svg_icon {
    font-size: 16px;
    cursor: pointer;
    margin-right: 12px;
  }

  .disableUpdateClass {
    fill: #b3b0ad;
    cursor: not-allowed;
  }

  .disableDelClass {
    color: #b3b0ad;
    cursor: not-allowed;
  }

  .campaignPaginationBox {
    text-align: center;
    position: relative;
    margin-top: 10px;
    .amount {
      position: absolute;
      top: 6px;
      right: 10px;
    }
  }
}
:deep(.el-input__suffix){
  display: flex;
  align-items: center;
}
</style>
